<template>
  <div class="header-bar">
      <div class="logo">
        <img src="logo.webp"/>
      </div>
      <span class="header-title"> Fun Tests </span>
      <div class="center">
        <div><a href="/">HOME</a></div>
      </div>
    </div>
        <div>
          <ins class="adsbygoogle"
     style="display:block"
     data-ad-format="fluid"
     data-ad-layout-key="-gv+s-3q-a5+wm"
     data-ad-client="ca-pub-3771475506771898"
     data-ad-slot="7033405800"></ins>
        </div>
      <div class="result" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
        <div class="result-data">
          <h1>{{ results.title }}</h1>
          <p>{{ results.desc }}</p>
        </div> 
      </div>

      <section class="tests">
        <h2>ALL Tests</h2>
        <div v-for="(test, index) in tests" :key="index" class="test-card">
          <div class="image-container">
            <img class="list-images" :src="test.image" :alt="test.alt">
            <div class="overlay">{{ test.title }}</div>
          </div>
          <div class="test-info">
            <h3>{{ test.title }}</h3>
            <span>heat: {{ calculateHeat(test.heat) }}</span>
            <button><router-link :to="{ name: 'TestDetail', params: { id: test.link, title: test.title }}">Start</router-link></button>
          </div>
        </div>
        <div>
          <ins class="adsbygoogle"
     style="display:block"
     data-ad-format="fluid"
     data-ad-layout-key="-gv+s-3q-a5+wm"
     data-ad-client="ca-pub-3771475506771898"
     data-ad-slot="7033405800"></ins>
        </div>
      </section>
</template>
<script>

import { useRoute } from 'vue-router';
export default {
  name: 'ResultPage',
  data() {
    const backgroundImage = require('@/assets/image/result-background.png');
    const resultList = require('@/assets/js/results.json')

    const route = useRoute();
    var key = route.query.id
    var results = resultList[key]

    var randomIndex = Math.floor(Math.random() * results.length);
    const result = results[randomIndex]
    console.log(result.title)
    return {
      results : result,
      tests: [
        { image: './image/life.webp', alt: 'lifestyle', title: 'Test of common sense in daily life?', heat: 99, link: 'life' },
        { image: './image/lifestyle.webp', alt: 'lifestyle guru', title: 'Test your lifestyle guru score?', heat: 3, link: 'lifestyle' },
        { image: './image/humor.webp', alt: 'humor index', title: 'Humor index?', heat: 31, link: 'humor' },
        { image: './image/strong.png', alt: 'Strength rating', title: 'Strength rating?', heat: 26, link: 'strong' },
        { image: './image/sexy.png', alt: 'Sexiness index', title: 'Sexiness index?', heat: 44, link: 'sexy' },
        { image: './image/firstlove.webp', alt: 'first love', title: 'Getting back together with your first love?', heat: 13, link: 'firstlove' },
        { image: './image/truelove.jpg', alt: 'true love', title: 'Probability of meeting true love?', heat: 66, link: 'truelove' },
        { image: './image/self-discipline.webp', alt: 'self-discipline', title: 'degree of self-discipline?', heat: 97, link: 'self-discipline' },
        { image: './image/fruit.png', alt: 'fruit', title: 'What kind of fruit are you?', heat: 51, link: 'fruit' },
        { image: './image/mbti.png', alt: 'MBTI', title: 'MBTI', heat: 23, link: 'mbti' },
        { image: './image/money.png', alt: 'money', title: 'Your ability to make money?', heat: 3, link: 'money' },
        { image: './image/personality.webp', alt: 'personality', title: 'personality test', heat: 38, link: 'personality' },
        { image: './image/boredom.png', alt: 'boredom', title: 'boredom level', heat: 35, link: 'boredom' },
        { image: './image/fashion.webp', alt: 'Fashion', title: 'Test Your Fashion Index?', heat: 74, link: 'fashion' },
        { image: './image/mentalage.webp', alt: 'mental', title: 'Test your mental age?', heat: 12, link: 'mentalage' },
        { image: './image/emotional.png', alt: 'Are you emotional or rational?', title: 'Are you emotional or rational?', heat: 234, link: 'emotional' },
        { image: './image/personalitycolor.webp',  alt: 'Personality', title: 'Test Personality Colors', heat: 67, link: 'personalitycolor' },
        { image: './image/soul.webp', alt: 'Soul', title: 'Testing the Gender of the Soul', heat: 4, link: 'soul' },
        //8.16新增
        { image: './image/workcity.webp', alt: 'workcity', title: 'What city are you suited to work in?', heat: 4, link: 'workcity' },
        { image: './image/SAS.webp', alt: 'SAS', title: 'SAS Self-Assessment Scale for Anxiety?', heat: 4, link: 'SAS' },
        { image: './image/lol.webp', alt: 'lol', title: 'Love or like?', heat: 4, link: 'lol' },
        { image: './image/stocks.webp', alt: 'stocks', title: 'Can you become a qualified investor?', heat: 4, link: 'stocks' },
        { image: './image/bestyou.webp', alt: 'bestyou', title: 'What are you best at?', heat: 4, link: 'bestyou' },
        { image: './image/making1.webp', alt: 'making1', title: 'decision-making style Ⅰ', heat: 4, link: 'making1' },
        { image: './image/making2.webp', alt: 'making2', title: 'decision-making style ⅠⅠ', heat: 4, link: 'making2' },
        { image: './image/animal1.webp', alt: 'animal1', title: 'What kind of animal personality Ⅰ', heat: 4, link: 'animal1' },
        { image: './image/animal2.webp', alt: 'animal2', title: 'What kind of animal personality ⅠⅠ', heat: 4, link: 'animal2' },
        { image: './image/dark.webp', alt: 'dark', title: 'The dark factor of personality', heat: 4, link: 'dark' },
      ],
      randomTests: [],
      backgroundImage
    };
  },
  mounted() {
    if (typeof window.adsbygoogle !== 'undefined') {
      window.adsbygoogle.push({});
    } else {
      const script = document.createElement('script');
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3771475506771898';
      script.async = true;
      script.crossOrigin = 'anonymous';
      script.onload = () => {
        window.adsbygoogle = window.adsbygoogle || [];
        window.adsbygoogle.push({});
      };
      document.head.appendChild(script);
    }
  },
  methods: {
    calculateHeat(baseHeat) {
      const startDate = new Date('2024-01-01');
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return baseHeat + diffDays;
    }
  }
};
</script>

<style>
.result {
  height: 30vh;
  background-size: 100% 100%;
  color: #fff;
}
.result-data {
  text-align: center;
  height: 20vh;
  padding:2vh 0;
}
.result h1 {
  margin-bottom: 2vh;
}


.tests {
    margin-bottom: 20px;
}

.tests h2 {
    font-size: 24px;
    margin-bottom: 10px;
}
.test-card {
  height: 25%;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px;
}


.image-container img {
  position: absolute; /* 绝对定位，确保图片覆盖整个容器 */
  top: 50%; /* 垂直居中 */
  left: 50%; /* 水平居中 */
  width: 100%; /* 图片宽度适应容器 */
  height: auto; /* 保持图片的宽高比 */
  padding-right: 10px;
  transform: translate(-50%, -50%); /* 将图片中心点移到容器中心 */
  object-fit: cover; /* 保持图片的宽高比，覆盖整个容器 */
}

.overlay {
  position: absolute; /* 绝对定位，确保文字覆盖在图片上 */
  top: 50%; /* 垂直居中 */
  left: 50%; /* 水平居中 */
  transform: translate(-50%, -50%); /* 将文字中心点移到容器中心 */
  color: white; /* 文字颜色 */
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  padding: 5px 10px; /* 内边距 */
  border-radius: 5px; /* 圆角 */
  text-align: center; /* 文字居中 */
}

.test-info {
    flex: 1;
}

.test-info h3 {
    font-size: 18px;
    margin-bottom: 5px;
}

.test-info span {
    display: block;
    margin-bottom: 10px;
    color: #888;
}

.test-info button {
    background-color: #ff5b5b;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.test-info button:hover {
    background-color: #ff3b3b;
}

/* Responsive design */
@media (max-width: 768px) {
    .test-card {
        align-items: flex-start;
    }

    .test-card img {
        margin-bottom: 10px;
    }
}
</style>
