<template>
  <div class="header-bar">
    <div class="logo">
      <a href="/"><img src="logo.webp" /></a>
    </div>
    <span class="header-title"> Fun Tests </span>
    <div class="center">
      <div><a href="/">HOME</a></div>
    </div>
  </div>
  <div class="about-content">
    <h1>Terms of Service</h1>
    <p>You are now reading the Terms of Service (the "Terms") that serve as an agreement between you and resoukandian
      ("us" or "we") for your use of our mobile device application resoukandian ("App"), and our news delivery services,
      accessible via our App. To make these Terms easier to read, our services, the Site (www.resoukandian.com) and the
      App are collectively called the "Services".</p>
    <h2>1. Privacy Policy</h2>
    <p>For information regarding the collection, use, and disclosure of data and information, in addition to your
      choices and control over such collection, use, and disclosure, please review the Privacy Policy</p>
    <h2>2. Prohibitions</h2>
    <p>It is upon agreement You agree and undertake that you must not carry out any of the following acts</p>
    <ol>
      <li>Use, display, emulate or frame the Services or any individual element within the Services, our name, any named
        trademark, logo or other proprietary information, or the layout and design of any page or form contained on a
        page, without our express written consent</li>
      <li>Access, tamper with, or use non-public areas of the Services, our computer systems, or the technical delivery
        systems of our providers</li>
      <li>Attempt to probe, scan or test the vulnerability of any system or network or breach any security or
        authentication measures</li>
      <li>Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure
        implemented by any of our providers or any other third party (including another user) to protect the Services or
        its Content ("Content")</li>
      <li>Attempt to access or search the Services or Content or download Content from the Services through the use of
        any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools
        or the like) other than the software and/or search agents provided by us or other generally available
        third-party web browsers</li>
      <li>Use any meta tags or other hidden text or metadata utilizing one of our trademark, logo URL or product name
        without our express written consent</li>
      <li>Use the Services or Content, or any portion thereof, for any commercial purpose or for the benefit of any
        third party or in any manner not permitted by these Terms</li>
      <li>Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in
        any way use the Services or Content to send altered, deceptive or false source-identifying information</li>
      <li>Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the
        Services or Content</li>
      <li>Interfere with, or attempt to interfere with, the access of any user, host or network, including, without
        limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services</li>
      <li>Collect or store any personally identifiable information from the Services from other users of the Services
        without their express permission</li>
      <li>Impersonate or misrepresent your affiliation with any person or entity</li>
      <li>Violate any applicable law or regulation</li>
      <li>Encourage or enable any other individual to do any of the foregoing</li>
    </ol>
    <p>Although it is not an obligatory function to monitor the use of the Services or Content, we retain the right to
      do so for the purpose of operating the Services. Ensuring that access to the services or content comply with these
      Terms, applicable law or other legal requirements. We retain the right to investigate violations of these Terms or
      conduct that affects the Services. We may also consult and cooperate with law enforcement authorities to prosecute
      users who violate the law</p>
    <h2>3. Copyright Policy</h2>
    <p>We respects copyright law. If you believe that a post infringes your copyrights, you may submit to us a
      notification pursuant to applicable law. Do not use this procedure for matters unrelated to copyright infringement
    </p>
    <p>Your notification of claimed infringement must include the following information: (1) an electronic or physical
      signature of the owner or of the person authorized to act on behalf of the owner of the copyright interest; (2)
      identification of the copyrighted work (or works) that you claim has (or have) been infringed; (3) a description
      of the material that you claim is infringing, and the location where the original or an authorized copy of the
      copyrighted work exists (for example, the URL of the page of the website where it is lawfully found; the name,
      edition and pages of a book from which an excerpt was copied, etc.); (4) a clear description of where the
      infringing material is located, including as applicable its URL, so that we can locate the material; (5) your
      name, address, telephone number, and e-mail address; (6) a statement that you have a good-faith belief that the
      disputed use is not authorized by the copyright owner, its agent, or the law; and (7) a statement by you, made
      under penalty of perjury, that the above information in your notice is accurate and that you are the copyright
      owner or authorized to act on the copyright owner’s behalf</p>
    <p>You can report alleged copyright infringement by emailing the above information to our email</p>
    <h2>4. Disclaimers</h2>
    <p>All information, content, links, websites or other resources on our APP’s are from third-party websites,
      advertising, or resources. We provide these links only as a convenience for you. We do not endorse, verify or
      vouch for and are not responsible for any view expressed, or the content, products or services on or available
      from those websites, advertising, or resources or links displayed on such websites. You acknowledge sole
      responsibility for and assume all risk arising from, your use of any third-party websites, advertising, or
      resources</p>
    <p>We and our licensers exclusively own all right, title and interest in and to the Services. All rights to the
      Content, News Content and Advertising Content are retained by the third-party that is the source of such Content.
      You acknowledge that the Services and Content are protected by copyright, trademark in accordance with applicable
      law. You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights
      notices incorporated in or accompanying the Services or Content</p>
    <p>This agreement does not authorize you to, and you may not, copy, distribute, transfer, modify, publicly display,
      publicly perform or create derivative works based upon Content without our or the third-party’s permission</p>
    <p>Without limiting the foregoing, WE EXPLICITLY DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
      PARTICULAR PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR
      USAGE OF TRADE. We do not warrant that the Services will meet your requirements or be available on an
      uninterrupted, secure, or error-free basis. We make no warranty regarding the quality, accuracy, timeliness,
      truthfulness, completeness or reliability of any Content. We do not create, prepare or maintain any News Content
      provided by or available on third-party websites or resources that are accessible via our Services, and we assume
      no liability or responsibility for any such third-party News Content. Under no circumstances will we be liable,
      directly or indirectly, to any party for any damage or loss caused or alleged to be caused by or in connection
      with the use of or reliance on any such News Content. Your use of and reliance on such News Content will be at
      your own risk</p>
    <p>You acknowledge and agree that as most of internet software and Services, the App Software or the Services may be
      under the influence of various factors including but not limited to reasons from the user, quality of the internet
      service and social environment, and may also be subject to various security problems including but not limited to
      actual disturbance in case of illegal use of user information as well as threatened insecurity of your terminal
      device information and data due to possible presence of viruses, Trojans or other malwares in other software
      downloaded by the user or other websites visited by the user, which in turn affect the normal use of the Software
      App or the Services. Therefore, you should have higher awareness of information safety and protection for personal
      information and pay attention to protecting your passwords to avoid losses</p>
    <h2>5. Limitation of Liability</h2>
    <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL WE BE LIABLE FOR ANY DAMAGES (INCLUDING,
      WITHOUT LIMITATION, INCIDENTAL AND CONSEQUENTIAL DAMAGES, PERSONAL INJURY/WRONGFUL DEATH, LOST PROFITS, OR DAMAGES
      RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION) RESULTING FROM THE USE OR INABILITY TO USE THIS WEB SITE OR APP
      OR THE CONTENT, OR ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, EFFECT, DELAY OR DEFECT IN OPERATION
      OR TRANSMISSION, VIRUS, LINE SYSTEM FAILURE, OR LOSS OF USE RELATED TO THIS WEB SITE OR APP, WHETHER BASED ON
      WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE ARE ADVISED OF THE POSSIBILITY OF SUCH
      DAMAGES. IF YOU ARE DISSATISFIED WITH THIS WEB SITE, APP, OR THESE TERMS OF Service, YOUR SOLE AND EXCLUSIVE
      REMEDY IS TO DISCONTINUE YOUR USE OF THE WEB SITE OR APP</p>
    <h2>6. Indemnification</h2>
    <p>Upon a request by us, you agree to defend, indemnify and hold harmless us and its affiliates, directors,
      officers, employees and agents against any claims, demands, actions, damages or other liabilities, including
      expenses and attorney’s fees, that arise from your use of this Web site or App. We reserves the right, at its own
      expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in
      which event you will cooperate with us in asserting any available defenses</p>
    <h2>7. Dispute Resolution</h2>
    <p>These Terms and any action related thereto will be governed by the laws of the People's Republic of China without
      regard to its conflict of laws provisions</p>
    <p>Any dispute or controversy arising between you and us shall first be resolved through friendly negotiation. If
      negotiation fails, you agree that the dispute or controversy shall be submitted to China International Economic
      and Trade Arbitration Commission, Beijing, China, for arbitration in accordance with its Rules of Arbitration</p>
    <h2>8. Rights and Terms for Apps</h2>
    <h3>8.1 Rights in App granted by Yoho</h3>
    <p>Subject to your compliance with these Terms, we grants you a limited non-exclusive, non-transferable,
      non-sublicenseable license to download and install a copy of the App on a mobile device or computer that you own
      or control and to run such copy of the App solely for your own personal non-commercial purposes. You may not copy
      the App, except for making a reasonable number of copies for backup or archival purposes. Except as expressly
      permitted in these Terms, you may not: (i) copy, modify or create derivative works based on the App; (ii)
      distribute, transfer, sublicense, lease, lend or rent the App to any third party; (iii) reverse engineer,
      decompile or disassemble the App; or (iv) make the functionality of the App available to multiple users through
      any means. We reserves all rights in and to the App not expressly granted to you under these Terms</p>
    <h3>8.2 Additional Terms for App Store App</h3>

    <p>If you accessed or downloaded the App from any app store or distribution platform (like Google Play or the Amazon
      Appstore) (each, an "App Provider"), then you acknowledge and agree that</p>

    <p>(1) * These Terms are concluded between you and us, and not with App Provider, and that, as between we and the
      App Provider, our trademark, is solely responsible for the App</p>

    <p>(2) * App Provider has no obligation to furnish any maintenance and support services with respect to the App</p>

    <p>(3) * App Provider is not responsible for addressing any claims you have or any claims of any third party
      relating to the App or your possession and use of the App, including, but not limited to: (i) product liability
      claims; (ii) any claim that the App fails to conform to any applicable legal or regulatory requirement; and (iii)
      claims arising under consumer protection or similar legislation</p>

    <p>(4) * In the event of any third-party claim that the App or your possession and use of the App infringes that
      third party’s intellectual property rights, we will be solely responsible for the investigation, defense,
      settlement and discharge of any such intellectual property infringement claim to the extent required by these
      Terms</p>

    <p>(5) * You must also comply with all applicable third-party terms of service when using the App</p>

    <p>(6) * You agree to comply with all U.S. and foreign export laws and regulations to ensure that neither the App
      nor any technical data related thereto nor any direct product thereof is exported or re-exported directly or
      indirectly in violation of, or used for any purposes prohibited by, such laws and regulations. By using the App
      you represent and warrant that: (i) you are not located in a country that is subject to a U.S. Government embargo,
      or that has been designated by the U.S. Government as a "terrorist supporting" country; and (ii) you are not
      listed on any U.S. Government list of prohibited or restricted parties</p>

    <h2>9. Links to Other Web sites or Applications</h2>
    <p>As a resource to Our users, Our Web sites or Apps may provide links to other sites. However, because we does not
      control the content of these other sites We may link to, We cannot be responsible for the content, practices or
      standards of third party sites. Inclusion of any third party link does not imply a recommendation or endorsement
      by Us. We are not responsible for and will not have any liability for any damages or injuries of any kind arising
      in connection with the content of linked third party sites, sites framed within Our Web sites or Apps, or
      third-party advertisements. We do not make any representations regarding the content or accuracy of third-party
      sites. Your use of third-party sites is at your own risk and subject to the terms and conditions of use for such
      sites</p>

    <h2>10. Operability of Mobile App</h2>
    <p>We do not warrant that the App will be compatible or interoperable with your mobile device or any hardware,
      software, equipment or device installed on your mobile device or used by you to access and use the mobile
      application in connection with your mobile device ("Accessories"). You acknowledge that compatibility and
      interoperability problems: (a) may cause the performance of the App, your mobile device and any Accessories to
      diminish or fail completely; (b) may result in permanent damage to your mobile device and any Accessories; (c) may
      result in a loss of data on your mobile device or Accessories; or (d) may result in the corruption of software and
      files located on your mobile device and any Accessories. You acknowledge and agree that we shall have no liability
      to you for any losses suffered resulting from or arising in connection with compatibility or interoperability
      problems</p>

    <h2>11. Other Terms and Conditions</h2>
    <p>If any provision of this Agreement is unlawful, void or unenforceable, you agree that the provision will be
      enforced to the maximum extent permissible and the remaining provisions contained in this Agreement shall continue
      in full force and effect</p>

    <h2>12. Changes to Terms of Service</h2>
    <p>We reserve the right to make additions, deletions, or modifications to these Terms of Service at any time without
      prior notification</p>

    <h2>13. Contact</h2>
    <p>We welcome all questions, concerns and feedback you might have about these terms. If you have suggestions for us,
      let us know by email</p>

    <p>E-mail: yanhui85j@gmail.com</p>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
  mounted() {
    
  }
}
</script>

<style scoped>
.about-content{
  background-color: #e1e0de;
  padding: 0 3em 3em 3em;
}
.about-content h1 {
  line-height: 3em;
}
.about-content h2 {
  line-height: 3em;
}
.about-content h3 {
  line-height: 2em;
}
.about-content p {
       text-indent: 3em; /* 将段落的首行缩进 2 个字符的宽度 */
       line-height: 1.9em;
   }
</style>
