<template>
  <div class="header-bar">
    <div class="logo">
      <a href="/"><img src="logo.webp" /></a>
    </div>
    <span class="header-title"> Fun Tests </span>
    <div class="center">
      <div><a href="/">HOME</a></div>
    </div>
  </div>
  <div class="about-content">
    <h1>Privacy Policy</h1>
    <p>This Privacy Policy (hereinafter referred to as "Policy") is aimed at assisting you to understand the privacy
      details about how resoukandian and its global affiliates (hereinafter referred to "us" and "We") manage your
      Personal Information that you provide to us when you are using our products or services, including how we collect,
      process and transmit your Personal Information, etc. Personal Information referred to in this Policy means all
      your data that we acquires when you browse our website and use our products or services. Personal Data mentioned
      in this Policy refers to the Personal Information that can be used to identify an individual on its own or in
      combination with other information. This Policy applies to our websites, products, and services that display or
      provide links to this Policy. Once you use our products or services, you shall be deemed to have read, agreed and
      accepted all terms and conditions stated in this Policy as well as all the changes we have made at any time. We
      respects and protects your personal privacy, please read carefully and understand this Policy before providing
      your Personal Information to us or allowing us to collect your Personal Information.</p>

    <h2>1. How we collects your Personal Information</h2>
    <p>In order to provide services to you or to better provide our services to you, we will acquire your Personal
      Information by the following ways no matter whether it is the Personal Data prescribed in this Policy or not:</p>

    <h3>1.1 Personal Information you provide to us when you surfer our website and use our products or services</h3>

    <p>For example, 1) The Personal Information that you submitted to set up and generate a personal account before
      using our services or products; and 2) The Personal Information that you provide to us to contact you for
      after-sales support or interaction in forum.</p>

    <h3>1.2 Information you provide to us when you send us a query, suggestion, or request</h3>

    <p>For example, after providing your Personal Data to us, you may want to know how we use and process your Personal
      Data and whether we disclose your Personal Data to third parties or not, or you may contact us to request deletion
      of your Personal Data.</p>

    <h3>1.3 Service-related information collected by our devices</h3>

    <p>For example, 1) we may collect necessary information to activate your after-sales service, software access, and
      forum interaction when you use our devices; and 2) we may collect logs generated by abnormal operation of the
      devices and information on users’ habit to optimize product experience during your use of our devices.</p>

    <h3>1.4 Personal Information collected when you use third party’s services or applications</h3>

    <p>As permitted by law, we may collect your Personal Information from third party’s social networking services or
      pre-installed third-party applications when you use them.</p>

    <h2>2. Types of Personal Information that may be collected by us</h2>
    <h3>2.1 Basic personal information</h3>

    <p>i. Registration information: such as phone number, name, birthday, nationality, age and your city, etc.</p>

    <p>ii. Account information: such as profile picture, delivery address, e-mail address and account, etc.</p>

    <p>iii. User information synchronized via cloud backup function or other applications.</p>

    <p>iv. Other basic information we may collect from you.</p>

    <h3>2.2 Service data information</h3>

    <p>i. Device information: including but not limited to device model, device identification number (IMEI and SN),
      mobile phone number, WIFI MAC address, PLMN, version of android system, version of software and network operator.
    </p>

    <p>ii. Device operation status and logs of operation: including but not limited to CPU load, storage status, device
      temperature, battery capacity, key words searched in browser or application, mobile data traffic/speed, signal
      intensity of mobile network, information of daemon, user habit, logs of abnormal operation of the device.</p>

    <p>iii. Location information: accurate or ambiguous location information we collect when you use navigation software
      or search weather conditions, longitude and latitude generated by GPS or Wi-Fi hotspot, community identity or
      country code, etc.</p>

    <p>iv. Product information: such as user’s feedback, third party’s applications installed on the phone, etc.</p>

    <p>v. Cookie information: our website, interactive application software, our products and advertising may collect
      and store your relevant Personal Information through cookie, pixel tags and web beacon.</p>

    <p>vi. Third-party application ID information: Android ID, Google Advertising ID, Facebook ID, What’s app ID, etc.
    </p>

    <p>Apart from the Personal Data mentioned in this Policy, Personal Information collected by us may also include
      non-identifiable information. Non-identifiable information refers to the Personal Information that could not lead
      to the identification of an individual, such as the number of visits to a website, APP downloads, and other
      statistics. We may decide on its own the processing, use, and disclosure of non-identifiable information.</p>

    <p>When we process your Personal Data based on this Privacy Policy or applicable laws, our entity who makes contact
      with you, or replies to your request is the controller of your Personal Data.</p>

    <h2>3. How we use your Personal Information</h2>
    <p>We shall comply with applicable legal requirements when using your Personal Information. Such requirements
      generally include:</p>

    <p>i. Processing of Personal Data on the basis of your consent with regard to a specific purpose;</p>

    <p>ii. Processing of your Personal Data for the fulfillment of legal obligations;</p>

    <p>iii. Data processing that is necessary for the performance of a contract you have entered into, or measures us
      shall take at your request prior to entering into a contract;</p>

    <p>iv. Data processing carried out for the legitimate interests pursued by us or a third party. Such legitimate
      interests include: 1) safety of our products, services, websites and its customers; 2) our property and honorary
      rights and interests, including fixed assets, intangible assets, goodwill, etc; 3) our internal management needs;
      4) our provision of products or services to users or better provision of products or services to users; 5) other
      legitimate interests of us or of a third party;</p>

    <p>v. Data processing that is necessary for protecting the vital interests of the data subject or of another natural
      person;</p>

    <p>vi. Data processing carried out for the performance of a task related to the public interest;</p>

    <p>We may use your Personal Information for the following purposes: i. Provide you with registration service and
      account management service.</p>

    <p>ii. Analyze your usage information, habits and preference to improve user experience.</p>

    <p>iii. Provide information which may interest you with your permission.</p>

    <p>iv. Provide good after sales service for you.</p>

    <p>v. Carry out macro customer survey and analyze our market share.</p>

    <p>vi. Feedback error report, locate quickly to solve abnormal issues.</p>

    <p>vii. Optimize performance of the device and improve functions design to provide better products and services for
      you.</p>

    <p>viii. Provide convenience for your information backup.</p>

    <p>ix. Preference configuration to provide the better use experience and improve the quality of the service.</p>

    <h2>4. How we disclose your Personal Information</h2>
    <p>Under the relevant applicable laws, we may disclose your Personal Information under the following situations:</p>

    <p>i. we may share your Personal Information within its internal group.</p>

    <p>ii. we may share your Personal Information with reliable partners or service providers. For example, when our
      partner is providing a service, we may share with the partner the necessary information, such as device
      identification code, to provide you with better service. Or when you buy our products on-line, we may provide your
      relevant Personal Information to logistics service provider to arrange product delivery.</p>

    <p>iii. Disclose your Personal Information if required by law. In order to execute or to assist in executing some
      legal or administrative procedures, we may provide your Personal Information to the relevant law enforcement
      agencies or government sectors.</p>

    <p>iv. we may disclose your Personal Information under other circumstances where such disclosure is required for
      universal justice as deemed by us. Such circumstances include the disclosure to prevent personal injury or death
      or to avoid significant property losses or to protect social public interest.</p>

    <h2>5. Cross-border transfer of your Personal Information</h2>
    <p>In order to provide better services for you, we may transfer your Personal Information to third countries for
      storing and processing, or your Personal Information may be accessed by other third countries. Privacy laws may
      vary among these countries, and we will take appropriate measures to process the Personal Information in
      accordance with the laws where the Personal Information is located.</p>

    <h2>6. How to access, modify and delete your Personal Data</h2>
    <p>As the user of the products or services, you are obliged to ensure the completeness and accuracy of the Personal
      Data you provided and carefully keep your Personal Data safe. We will protect and update your Personal Data on
      time and try our best to ensure your normal use of the products and services.</p>

    <p>In accordance with your rights under relevant applicable law, you may exercise: 1) right of access, i.e. the
      right to have access to your Personal Data held by us and to know whether Personal Data concerning him or her are
      being processed or not; 2) right to rectification, i.e. the right to request rectification of the inaccurate
      Personal Data we hold concerning you; 3) right to erasure, i.e. the right to request us to erasure of the Personal
      Data concerning you; 4) right to restriction of processing, i.e. the right to restrict our processing of your
      Personal Data; 5) right to object, i.e. the right to object to our processing of your Personal Data; 6) right to
      data portability, i.e. the right to request us, where technically feasible, to have your Personal Data transmitted
      to another data controller. To request exercising your rights under the applicable law, please fill out the form
      attached to this Policy and send to us via email. We will reply to you in time.</p>

    <p>While you are exercising your rights under the applicable law, we may investigate the legality and rationality of
      your request. If we have reasonable grounds or suspect that your request is deceptive, repetitive or infringes
      upon the rights and freedom of third parties, or if we are unable to get through the investigation, we may ask you
      to provide further information or may refuse to process your request. You may send your request in written form in
      order to protect your legitimate rights and interests. After receiving your request, we will process within the
      period of time as prescribed by applicable law. If you consider that we are in violation of applicable law and
      have infringed your legitimate rights, you may file a complaint with the authority for the protection of Personal
      Data in order to resolve the issue.</p>

    <h2>7. Security measures we take to protect your Personal Information</h2>
    <p>We will provide security measures to our users. We will take reasonable and practicable measures to protect your
      Personal Data that we collected and prevent your Personal Data from being accessed, tampered, disclosed or
      destroyed by unauthorized third parties. For example, when you access our account, we have user validation
      procedure or password retrieve procedure to ensure the security of your account.</p>

    <p>In the matter of the storage of Personal Data, we have provided security measures to you as well. All your
      Personal Data will be stored in the secure server, and we will control and protect all the Personal Data. We will
      determine the storage period of your Personal Data in accordance with the terms and conditions of applicable law.
      From the perspective of protecting you and your privacy right to the maximum extent, except as otherwise
      compulsorily stated by law, we will limit the period for which your Personal Data is stored to a strict minimum
      degree provided that it satisfies the data processing purposes prescribed in this Policy. Our criteria for how
      long we keep Personal Data mainly include the following two points: 1) time needed to store Personal Data for
      completing the relevant business; 2) terms and conditions of applicable law. In addition, there are duties of
      confidentiality in the contract signed between us and its partners. If a partner breaches the confidentiality
      obligation in the contract and brings about losses to the obligee, we will impose legal liabilities on the partner
      and terminate the business relationship with the partner.</p>

    <p>There is no absolutely safe website, Internet transmission, computer system and wireless connections. Except the
      necessary protective measures we have taken, users have to strengthen the awareness of privacy security risks. For
      example, when skipping to third party websites, links, products or services through our services, please carefully
      read through the terms and conditions or privacy policies of such websites, links, products or services regarding
      the security of the data and information collected by them. We shall not be liable for any actions you make
      thereof. If you find certain contents, advertisements, or functions in our website, products or services that may
      be possibly provided by third parties, and may possibly endanger your privacy and security, please contact us with
      email and we will handle with priority.</p>

    <h2>8. How we handle Personal Data of minors</h2>
    <p>Minors are not allowed to use our products and services without the consent and guidance of his or her legal
      guardians. In principle, we will not take initiative to collect the Personal Data of a minor and only with the
      consent of his or her legal guardians, we will collect his or her Personal Data. As to the disclosure of the
      Personal Data of a minor, we will provide, in principle, only when permission is given by his/her legal guardian,
      or where the law or relevant government sector legitimately requires. In addition, if you find that a minor sends
      his or her Personal Data to us without the consent of his or her guardian, please fill out the form attached to
      this Policy and send to us via email. We will reply to you in time.</p>

    <h2>9. Products and services from third parties</h2>
    <p>Our privacy policy does not apply to any products or services provided by third parties. You may receive links of
      websites, contents and services from third parties when you use our products or services. Please carefully read
      and understand the privacy policy of third parties before you submit any data and information, for we cannot
      control third parties and cannot require them to abide by our Policy.</p>

    <h2>10. Complement to the Policy</h2>
    <p>This Policy mainly lays out how we will collect, process and internationally transmit your Personal Information
      when you browse our website and use our products or services. However, it is impossible to complete enumeration
      how We collect data, what kind of data We collect, how We use and process the data and the conditions We disclose
      the data in this Policy. We may send the complementary notice when we collect, process, disclose and
      internationally transmit your specific Personal Information.</p>

    <h2>11. Update of this Policy</h2>
    <p>We reserves the right to modify this Policy.</p>

    <h2>12. How to contact us</h2>
    <p>We has appointed a Data Protection Officer. For any questions in relation to the protection of personal privacy,
      please contact our Data Protection Officer by filling out the form attached to this Policy and sending the
      completed form to our email. If you have any questions or suggestions about this Policy, please contact us by
      email.</p>

    <p>E-mail：yanhui85j@gmail.com</p>
  </div>
</template>

<script>
export default {
  name: 'PolicyPage',
  mounted() {
    
  }
}
</script>

<style scoped>
.about-content{
  background-color: #e1e0de;
  padding: 0 3em 3em 3em;
}
.about-content h1 {
  line-height: 3em;
}
.about-content h2 {
  line-height: 3em;
}
.about-content h3 {
  line-height: 2em;
}
.about-content p {
       text-indent: 3em; /* 将段落的首行缩进 2 个字符的宽度 */
       line-height: 1.9em;
   }
</style>
