<!-- src/components/ProgressBar.vue -->
<template>
  <div class="progress-bar">
    <div v-for="(step, index) in totalSteps" :key="index" :class="['step', { active: index < currentStep }]"></div>
  </div>
</template>

<script>
export default {
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
      default: 5
    }
  }
};
</script>

<style scoped>
.progress-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  height: 20vh;
}
.step {
  flex: 1;
  height: 10px;
  margin: 0 5px;
  background: #ccc;
  border-radius: 5px;
  transition: background 0.3s;
}
.step.active {
  background: #4caf50;
}
</style>
