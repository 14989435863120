<template>
  <div id="app">
    <div class="header-bar">
      <div class="logo">
        <img src="logo.webp"/>
      </div>
      <span class="header-title"> Fun Tests </span>
      <div class="center">
        <div><a href="/">HOME</a></div>
      </div>
    </div>
    <header>
      <input type="text" placeholder="Search" v-model="searchQuery" @input="filterTests">
    </header>
    <main>
      <section class="banner" v-if="!searchQuery">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(test, index) in randomTests" :key="index">
              <router-link :to="{ name: 'TestDetail', params: { id: test.link } }">
                <img :src="test.image" :alt="test.alt" class="banner-image">
              </router-link>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </section>
      <section>
        <h2 style="margin:20px 0;">All Tests</h2>
        <div class="all-img">
          <div v-for="(test, index) in filteredTests" :key="index" class="img-card">
            <div>
              <router-link :to="{ name: 'TestDetail', params: { id: test.link, title: test.title }}">
                <img class="img-card-images" :src="test.image" :alt="test.alt">
              </router-link>
            </div>
          </div>
        </div>
      </section>
      <section class="ai">
      <h2 style="margin:20px 0;">AI LIST</h2>
       <ul class="ai-show">
        <li
          v-for="(data, index) in ais" :key="index" 
          class="ai-item" >
          <a
            :href="data.link"
            target="_blank"
            data-sdk-report="1"
            rel="nofollow"
            class="ai-item-2"
            data-sdk-marked="1"
          >
            <div class="ai-item-logo">
              <img :src="data.logo" :alt="data.title" />
            </div>
            <div class="amz-intro">
              <p class="amz-item-title" style="color: rgb(68, 68, 68);">
                {{ data.title }}
              </p>
              <p class="amz-item-intro description" :title="data.desc">
                {{ data.desc }}
              </p>
            </div>
          </a>
        </li>
      </ul>
      </section>
      <section class="tests">
        <h2 style="margin:20px 0;">Related Tests</h2>
        <div>
          <ins class="adsbygoogle"
     style="display:block"
     data-ad-format="fluid"
     data-ad-layout-key="-gv+s-3q-a5+wm"
     data-ad-client="ca-pub-3771475506771898"
     data-ad-slot="7033405800"></ins>
        </div>
        <div v-for="(test, index) in filteredTests" :key="index" class="test-card">
          <div class="image-container">
            <img class="list-images" :src="test.image" :alt="test.alt">
            <div class="overlay">{{ test.title }}</div>
          </div>
          <div class="test-info">
            <h3>{{ test.title }}</h3>
            <span>heat: {{ calculateHeat(test.heat) }}</span>
            <button><router-link :to="{ name: 'TestDetail', params: { id: test.link, title: test.title }}">Start</router-link></button>
          </div>
        
        </div>

         <div>
          <ins class="adsbygoogle"
     style="display:block"
     data-ad-format="fluid"
     data-ad-layout-key="-gv+s-3q-a5+wm"
     data-ad-client="ca-pub-3771475506771898"
     data-ad-slot="7033405800"></ins>
        </div>
      </section>
    </main>
  </div>
</template>

<script>

// import {onMounted, onBeforeUnmount } from 'vue';
import Swiper, { Pagination, Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';

Swiper.use([Pagination, Navigation, Autoplay]);

export default {
  name: 'HomePage',
  data() {
    return {
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      swiperInstance: null,
      tests: [
        { image: './image/life.webp', alt: 'lifestyle', title: 'Test of common sense in daily life?', heat: 99, link: 'life' },
        { image: './image/lifestyle.webp', alt: 'lifestyle guru', title: 'Test your lifestyle guru score?', heat: 3, link: 'lifestyle' },
        { image: './image/humor.webp', alt: 'humor index', title: 'Humor index?', heat: 31, link: 'humor' },
        { image: './image/strong.png', alt: 'Strength rating', title: 'Strength rating?', heat: 26, link: 'strong' },
        { image: './image/sexy.png', alt: 'Sexiness index', title: 'Sexiness index?', heat: 44, link: 'sexy' },
        { image: './image/firstlove.webp', alt: 'first love', title: 'Getting back together with your first love?', heat: 13, link: 'firstlove' },
        { image: './image/truelove.jpg', alt: 'true love', title: 'Probability of meeting true love?', heat: 66, link: 'truelove' },
        { image: './image/self-discipline.webp', alt: 'self-discipline', title: 'degree of self-discipline?', heat: 97, link: 'self-discipline' },
        { image: './image/fruit.png', alt: 'fruit', title: 'What kind of fruit are you?', heat: 51, link: 'fruit' },
        { image: './image/mbti.png', alt: 'MBTI', title: 'MBTI', heat: 23, link: 'mbti' },
        { image: './image/money.png', alt: 'money', title: 'Your ability to make money?', heat: 3, link: 'money' },
        { image: './image/personality.webp', alt: 'personality', title: 'personality test', heat: 38, link: 'personality' },
        { image: './image/boredom.png', alt: 'boredom', title: 'boredom level', heat: 35, link: 'boredom' },
        { image: './image/fashion.webp', alt: 'Fashion', title: 'Test Your Fashion Index?', heat: 74, link: 'fashion' },
        { image: './image/mentalage.webp', alt: 'mental', title: 'Test your mental age?', heat: 12, link: 'mentalage' },
        { image: './image/emotional.png', alt: 'Are you emotional or rational?', title: 'Are you emotional or rational?', heat: 234, link: 'emotional' },
        { image: './image/personalitycolor.webp',  alt: 'Personality', title: 'Test Personality Colors', heat: 67, link: 'personalitycolor' },
        { image: './image/soul.webp', alt: 'Soul', title: 'Testing the Gender of the Soul', heat: 4, link: 'soul' },
        //8.16新增
        { image: './image/workcity.webp', alt: 'workcity', title: 'What city are you suited to work in?', heat: 4, link: 'workcity' },
        { image: './image/SAS.webp', alt: 'SAS', title: 'SAS Self-Assessment Scale for Anxiety?', heat: 4, link: 'SAS' },
        { image: './image/lol.webp', alt: 'lol', title: 'Love or like?', heat: 4, link: 'lol' },
        { image: './image/stocks.webp', alt: 'stocks', title: 'Can you become a qualified investor?', heat: 4, link: 'stocks' },
        { image: './image/bestyou.webp', alt: 'bestyou', title: 'What are you best at?', heat: 4, link: 'bestyou' },
        { image: './image/making1.webp', alt: 'making1', title: 'decision-making style Ⅰ', heat: 4, link: 'making1' },
        { image: './image/making2.webp', alt: 'making2', title: 'decision-making style ⅠⅠ', heat: 4, link: 'making2' },
        { image: './image/animal1.webp', alt: 'animal1', title: 'What kind of animal personality Ⅰ', heat: 4, link: 'animal1' },
        { image: './image/animal2.webp', alt: 'animal2', title: 'What kind of animal personality ⅠⅠ', heat: 4, link: 'animal2' },
        { image: './image/dark.webp', alt: 'dark', title: 'The dark factor of personality', heat: 4, link: 'dark' },
      ],
      randomTests: [],
      searchQuery: '',
      ais: [
        {
          link: 'https://chat.openai.com',
          logo: 'https://cdn.oaistatic.com/assets/apple-touch-icon-mz9nytnj.webp',
          title: 'ChatGPT',
          desc: 'The strongest AI chatbot on the surface.'
        },
        {
          link: 'https://yiyan.baidu.com',
          logo: 'https://nlp-eb.cdn.bcebos.com/logo/favicon.ico',
          title: 'Wenxin Yiyan',
          desc: 'An AI dialogue and interaction tool based on Baidu\'s Wenxin large model.'
        },
        {
          link: 'https://www.bing.com',
          logo: './image/bing.png',
          title: 'Bing',
          desc: 'The new version of Bing launched by Microsoft combined with ChatGPT function.'
        },
        {
          link: 'https://www.baichuan-ai.com/home',
          logo: '//cdn.baichuan-ai.com/build/_next/static/media/favicon-96x96.b3239d19.png',
          title: 'Baichuan large model',
          desc: 'It integrates intention understanding, information retrieval and reinforcement learning technology, and performs outstandingly in the fields of knowledge Q&A and text creation.'
        },
        {
          link: 'https://www.chat123.ai/amz123?form=ltai',
          logo: 'https://cdn.oaistatic.com/assets/apple-touch-icon-mz9nytnj.webp',
          title: 'Chat123 cross-border AI assistant',
          desc: 'Cross-border intelligent chatbot that easily solves various problems in going overseas.'
        },
        {
          link: 'https://www.jasper.ai/chat',
          logo: 'https://cdn.prod.website-files.com/60e5f2de011b86acebc30db7/666f3335624f2206269a6688_Webclip.png',
          title: 'Jasper Chat',
          desc: 'An AI chat tool produced by Jasper for content creators.'
        },
        {
          link: 'https://volctrack.com/w/pj2v53S1',
          logo: '//statics.moonshot.cn/kimi-chat/favicon.ico',
          title: 'Kimi intelligent assistant',
          desc: 'Kimi intelligent search, answer immediately, not afraid of the flood of information.'
        },
        {
          link: 'https://www.gnomic.cn/?amz123',
          logo: 'https://buff8.oss-cn-shanghai.aliyuncs.com/sublime_words/logo_1.png',
          title: 'Gnomic intelligent agent platform',
          desc: 'CarrotAI-GPT4 is freely available, stable and without ads, and can create intelligent agents.'
        },
        {
          link: 'https://ai3.bz007.cn',
          logo: 'https://ai3.bz007.cn/favicon.ico',
          title: 'Daytime AI',
          desc: 'Free GPT3.5 supports GPT4.0 Midjourney!!!'
        },
        {
          link: 'https://www.anthropic.com/',
          logo: 'https://www.anthropic.com/images/icons/apple-touch-icon.png',
          title: 'Anthropic',
          desc: 'A chatbot released by Anthropic to compete with ChatGPT.'
        },
        {
          link: 'https://poe.com',
          logo: 'https://psc2.cf2.poecdn.net/assets/favicon.svg',
          title: 'Poe',
          desc: 'A Q&A robot tool launched by Quora, a Q&A community.'
        },
        {
          link: 'https://you.com/chat',
          logo: './image/you.webp',
          title: 'YouChat',
          desc: 'AI search and dialogue tool.'
        },
        {
          link: 'https://ingestai.io',
          logo: './image/ingestai.png',
          title: 'IngestAI',
          desc: 'A tool that helps people transform their knowledge base into an assistant similar to a chatbot. This tool is compatible with Slack, Discord, Whatsapp, Telegram, etc. Get started in minutes and support uploading knowledge base files such as Markdown, TXT, Word, Excel, PowerPoint, Notion, Confluence, etc.'
        },
        {
          link: ' https://215.im',
          logo: '//ac215.oss-cn-shanghai.aliyuncs.com/favicon.png',
          title: 'Jiujiu Ai',
          desc: 'Your exclusive AI friend!'
        },
        {
          link: 'https://copilot.microsoft.com/',
          logo: 'https://copilot.microsoft.com/sa/simg/favicon-cplt.ico',
          title: 'Copilot',
          desc: 'It can help write, rewrite, improve or optimize article content and other functions.'
        },
        {
          link: 'https://chat.mistral.ai/',
          logo: 'https://storage.googleapis.com/bac-gcs-production/c7ee119eb4cfacb124a813f99e042b9b9ddd84d758047ecb810b1d70e849ee9357b38229a47ff06b6c81f6cdbc901bf364aa7c522ce897e3aae187dd73848d2d.png',
          title: 'Le Chat',
          desc: 'Provides an educational and interesting way to explore MistralAI technology.'
        },
        {
          link: 'https://www.midjourney.com/',
          logo: 'https://www.midjourney.com/apple-touch-icon.png',
          title: 'Midjourney',
          desc: 'Currently the strongest AI painting tool.'
        },
        {
          link: 'https://stability.ai/',
          logo: 'https://images.squarespace-cdn.com/content/v1/6213c340453c3f502425776e/cfbf5bc5-47d4-4f4a-b133-23bd12a7d7c2/favicon.ico?format=100w',
          title: 'Stable Diffusion',
          desc: 'The strongest open-source AI painting tool.'
        },
        {
          link: 'https://nijijourney.com/zh/',
          logo: './image/niji.png',
          title: 'NijiJourney',
          desc: 'Produced by MJ! Oriented towards the secondary style, with professional and detailed content control.'
        },
        {
          link: 'https://beta.dreamstudio.ai/',
          logo: 'https://nijijourney.com/assets/sizigi/apple-touch-icon.png',
          title: 'DreamStudio',
          desc: "SD's brother product! An AI image generator."
        },
        {
          link: 'https://leonardo.ai/',
          logo: 'https://leonardo-cdn.b-cdn.net/wp-content/uploads/2024/07/cropped-favicon-192x192.png',
          title: 'Leonardo',
          desc: 'An AI drawing community! Train your own game asset model.'
        },
        {
          link: 'https://www.xiaomendao.cn/',
          logo: './image/xiaomendao.ico',
          title: 'Xiaomendao AI',
          desc: 'GPT4.0, AutoGPT, MJ drawing.'
        },
        {
          link: 'https://www.jijianzn.com/',
          logo: 'https://img.amz123.com/image_cloud/202308/1693281359305_%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230829115439.png',
          title: 'Jijian Intelligent King',
          desc: 'A one-stop AI creation tool to improve the efficiency of minimalist creation.'
        },
        {
          link: 'https://yun800.com/',
          logo: './image/jijian.ico',
          title: 'Yunba',
          desc: 'A professional AI painting tool, Midjourny web version, Stable Diffusion web version.'
        },
        {
          link: 'https://dreamlike.art/',
          logo: 'https://framerusercontent.com/modules/6ScDhke80BkrerN7Yfji/OJeWQfexOX7Om5ZRMOYl/assets/QUbaKg7h1kTm0wLek7YB9vhNe1c.png',
          title: 'Dreamlike.art',
          desc: 'Amazing effect! An AI image generator with 5 built-in models.'
        },
        {
          link: 'https://www.dreamup.com',
          logo: 'https://st.deviantart.net/eclipse/icons/pwa/ios-180.png',
          title: 'DreamUp',
          desc: 'An AI illustration generation tool launched by DeviantArt.'
        },
        {
          link: 'https://www.artbreeder.com',
          logo: 'https://www.artbreeder.com/favicon-light.png',
          title: 'Artbreeder',
          desc: 'Create stunning illustrations and art.'
        },
        {
          link: 'https://www.zcool.com.cn/ailab',
          logo: '//static.zcool.cn/git_z/z/site/favicon.ico?version=1618914637608',
          title: 'ZCOOL Dream Pen',
          desc: 'An artificial intelligence illustration generation tool launched by ZCOOL, a well-known domestic design community.'
        },
        {
          link: 'https://www.fooocus.cc/',
          logo: 'https://www.fooocus.cc/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffavicon_rbg64.9cfd38d1.ico&w=128&q=75',
          title: 'Fooocus',
          desc: 'Simple operation! Generate high-quality images for free.'
        },
        {
          link: 'https://flux1.ai/',
          logo: './image/flux.ico',
          title: 'Flux.1',
          desc: 'A new benchmark for AI painting, a super-strong open-source text-to-image model.'
        }
      ],
    };
  },
  computed: {
    filteredTests() {
      if (this.searchQuery) {
        return this.tests.filter(test => test.title.toLowerCase().includes(this.searchQuery.toLowerCase()));
      } else {
        return this.tests;
      }
    }
  },
  created() {
    this.randomTests = this.getRandomTests(3);
  },
  methods: {
    getRandomTests(count) {
      const shuffled = this.tests.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, count);
    },
    filterTests() {
      // var testList = this.tests.filter(test => test.title.toLowerCase().includes(this.searchQuery.toLowerCase()));
      // this.tests = testList
    },
    calculateHeat(baseHeat) {
      const startDate = new Date('2024-01-01');
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return baseHeat + diffDays;
    }
  },
  mounted() {
    this.swiperInstance = new Swiper('.swiper-container', this.swiperOptions);
    if (typeof window.adsbygoogle !== 'undefined') {
      window.adsbygoogle.push({});
    } else {
      const script = document.createElement('script');
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3771475506771898';
      script.async = true;
      script.crossOrigin = 'anonymous';
      script.onload = () => {
        window.adsbygoogle = window.adsbygoogle || [];
        window.adsbygoogle.push({});
      };
      document.head.appendChild(script);
    }
  },
  beforeUnmount() {
    if (this.swiperInstance) {
      this.swiperInstance.destroy(true, true);
    }
  },
};
</script>

<style>
/* styles.css */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

#app {

    height: 100%;
    width:100%;
}

body {
    height: 100%;
    width:100%;
    padding: 10px;
    background-color: rgb(239, 239, 243);
}

a {
    all: unset;
    /* 移除所有默认样式 */
    display: inline;
    /* 设置显示为行内元素 */
    cursor: pointer;
    /* 保持手型光标 */
    color: inherit;
    /* 继承父元素的颜色 */
    text-decoration: none;
    /* 移除下划线 */
}

header {
    text-align: center;
    margin-bottom: 20px;
}

header input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
}

.banner {
  justify-content: center;
  width: 100%;
  height: 24%; /* 高度占屏幕的1/4 */
  position: relative;
  overflow: hidden;
}


.banner-image {
    width: 100%; /* 图片宽度适应容器 */
    height: 25vh; /* 图片高度适应容器 */
    object-fit: cover; /* 保持图片的宽高比，覆盖整个容器 */
    object-position: center; /* 图片居中显示 */
}


.tests {
    margin-bottom: 20px;
}
.all-img {
  display: flex;
  flex-wrap: wrap; /* 使元素自动换行 */
  justify-content: space-between; /* 使元素在容器中均匀分布 */
  
}

.img-card{
  flex: 1 1 calc(25% - 20px); /* 设置每个卡片占据三分之一宽度，减去边距 */
  width: 300px;
  box-sizing: border-box;
  margin: 10px; /* 间距 */
  height: auto; /* 根据内容自动调整高度 */
  display: flex;
  flex-direction: column; /* 使内部内容垂直排列 */
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}
.img-card img{
  height: 28em;
  position: relative;

}

.tests h2 {
    font-size: 24px;
    margin-bottom: 10px;
}
.test-card {
  height: 25%;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px;
}

  .image-container {
      width: 15%;
      height: 10em;
      overflow: hidden;
      position: relative;
      padding: 0;
      border-radius: 11px;
  }

.image-container img {
  position: absolute; /* 绝对定位，确保图片覆盖整个容器 */
  top: 50%; /* 垂直居中 */
  left: 50%; /* 水平居中 */
  width: 100%; /* 图片宽度适应容器 */
  height: auto; /* 保持图片的宽高比 */
  padding-right: 10px;
  transform: translate(-50%, -50%); /* 将图片中心点移到容器中心 */
  object-fit: cover; /* 保持图片的宽高比，覆盖整个容器 */
}

.overlay {
  position: absolute; /* 绝对定位，确保文字覆盖在图片上 */
  top: 50%; /* 垂直居中 */
  left: 50%; /* 水平居中 */
  transform: translate(-50%, -50%); /* 将文字中心点移到容器中心 */
  color: white; /* 文字颜色 */
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  padding: 5px 10px; /* 内边距 */
  border-radius: 5px; /* 圆角 */
  text-align: center; /* 文字居中 */
}

.test-info {
    flex: 1;
}

.test-info h3 {
    font-size: 18px;
    margin-bottom: 5px;
}

.test-info span {
    display: block;
    margin-bottom: 10px;
    color: #888;
}

.test-info button {
    background-color: #ff5b5b;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.test-info button:hover {
    background-color: #ff3b3b;
}

/* 设置footer在页面底部 */
.footer-bottom {
  bottom: 0;
  width: 100%;
  background-color: #617a99; /* 设置背景颜色 */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* 增加左右间距 */
  box-sizing: border-box;
  color:#fff
}

/* 设置左侧内容样式 */
.left-bottom {
  padding: 1em 0;
  font-size: 16px;
}

/* 设置右侧内容样式 */
.right-bottom {
  display: flex;
  align-items: center;
}

/* 右侧内容中各项之间的间距 */
.right-bottom > div {
  margin-left: 20px;
}

/* 链接样式 */
.right-bottom a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
}

.right-bottom a:hover {
  text-decoration: underline;
}
/* 设置header-bar的基础样式 */
.header-bar {
  width: 100%;
  height: auto; /* 高度自适应 */
  background-color: rgb(239, 239, 243); /* 背景颜色 */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px; /* 设置内边距 */
  box-sizing: border-box;
  margin-bottom: 10px;
}

/* 设置logo部分的样式 */
.header-bar .logo {
  display: flex;
  justify-content: flex-start; /* 使logo靠左 */
  align-items: center;
}
.header-bar .header-title {
  font-size: 2em;
  font-weight: 700;
}

/* 设置logo图片的样式 */
.header-bar .logo img {
    max-height: 4.5em;
    height: auto;
    border-radius: 30%;
    overflow: hidden;
}

/* center内部每个链接的样式 */
.header-bar .center div {
  background-color: #1f825cb8;
    border-radius: 8px;
    padding: 0.8em;
    margin: 0 15px;
}

/* 链接样式 */
.header-bar .center a {
  text-decoration: none;
  color: #d5d6da;
  font-size: 18px;
  font-weight: 500;
}
/* ai 相关样式 */
.ai-show {
  display: flex;
  flex-wrap: wrap; /* 如果想让元素自动换行 */
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ai-item {
    flex: 0 1 10em;
    margin: 1rem;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #fff;
}

.ai-item-logo img {
  padding: 2rem 0 0 1rem;
  max-width: 100px; /* Logo的最大宽度，可根据需要调整 */
  height: auto;
}

.amz-intro {
  padding: 10px;
}

.amz-item-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.amz-item-intro {
  color: #666;
  font-size: 14px;
  height: 6rem;
  overflow: hidden;
}
/* 响应式设计 */
@media (max-width: 1025px) {
  .test-card {
        align-items: flex-start;
    }

    .test-card img {
        margin-bottom: 10px;
    }
  .header-bar {
    text-align: center; /* 文本居中 */
  }
  
  .header-bar .logo, .header-bar .center {
    flex: none; /* 取消flex属性，使其自动调整宽度 */
    margin-bottom: 10px; /* 添加下边距 */
    
  }
  .image-container {
    width: 45%; /* 你想要的具体宽度，可以是百分比或者固定值 */
    height: 7em; /* 你想要的具体高度 */
    overflow: hidden; /* 确保超出部分被隐藏 */
    position: relative; /* 确保子元素的定位是相对于这个容器的 */
    padding: 0; /* 移除内边距 */
  }
  .header-bar .center div {
    margin: 5px 0; /* 调整小屏幕下链接的间距 */
  }
  .img-card img {
    height: 5em;
    position: relative;
}
}
@media  (min-width: 1025px) {
  .ai-item {
    flex: 0 1 20em;
    margin: 1rem;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #fff;
}
 .banner {
    /* 在此处添加您的特殊样式，例如 */
    width: 100%;
    font-size: 20px;
    height: 100%;
    justify-content: center;
    position: relative;
    background-color: rgb(86, 84, 87);
    /* overflow: hidden; */
  }
  .banner-image {
    width: 50%; /* 图片宽度适应容器 */
    height: 50vh; /* 图片高度适应容器 */
    object-fit: cover; /* 保持图片的宽高比，覆盖整个容器 */
    object-position: center; /* 图片居中显示 */
    display: block; 
    margin: auto;
  }
  .swiper-container {
    display: flex;
    margin: auto 0;
  }
  .test-card {
    height: 40%;
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 10px;
  }
}

</style>
