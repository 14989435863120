<!-- src/components/QuizPage.vue -->
<template>
  <div class="quiz-container" :style="{ backgroundImage: 'url(' + selectedBackgroundImage + ')' }">
    <ProgressBar :currentStep="currentStep" :totalSteps="totalSteps" />
    <div v-if="currentStep <= totalSteps" class="question">
      <div class="title">{{ currentQuestion.question }}</div>
      <transition-group name="fade" tag="div" class="options">
        <div v-for="(option, index) in currentQuestion.options" :key="index" class="option" @click="selectOption(index)">
          <div class="option-inside">
            <div class="option-inside-text">
                {{ option }}
            </div>
        </div>
        </div>
      </transition-group>
    </div>
  </div>

   <div ><!-- funtest-details-page -->
<ins class="adsbygoogle"
     style="display:block;"
     data-ad-client="ca-pub-3771475506771898"
     data-ad-slot="4521742788"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins></div>
</template>

<script>
import { ref, computed } from 'vue';
import ProgressBar from './ProgressBar.vue';
import { useRoute, useRouter } from 'vue-router';


export default {
  components: {
    ProgressBar
  },
  setup() {
    const currentStep = ref(1);
    const totalSteps = 5;
    var backgroundImage = require('@/assets/image/backgroundimg.png'); // Make sure the path is correct
    var backgroundImageApp = require('@/assets/image/backgroundimg-app.webp'); // Make sure the path is correct
    const questionList = require('@/assets/js/questions.json')
    const route = useRoute();
    const router = useRouter();
    const selectedBackgroundImage = ref('');
    var key = route.query.id
    var questions = questionList[key];
    const currentQuestion = computed(() => questions[currentStep.value - 1]);

    const selectOption = () => {
      if (currentStep.value < totalSteps) {
        currentStep.value++;
      } else if (currentStep.value ==totalSteps) {
        router.push(`/result?id=${key}`);
      }
    };

    const isMobileBrowser = () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    selectedBackgroundImage.value = isMobileBrowser() ? backgroundImageApp : backgroundImage;

    return {
      currentStep,
      totalSteps,
      backgroundImage,
      selectedBackgroundImage,
      currentQuestion,
      selectOption,
      key
    };
  },
  mounted() {
    if (typeof window.adsbygoogle !== 'undefined') {
      window.adsbygoogle.push({});
    } else {
      const script = document.createElement('script');
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3771475506771898';
      script.async = true;
      script.crossOrigin = 'anonymous';
      script.onload = () => {
        window.adsbygoogle = window.adsbygoogle || [];
        window.adsbygoogle.push({});
      };
      document.head.appendChild(script);
    }
  },
  methods: {
      getResult(key) {
          this.$router.push('/result?id='+key);
      },
  },
};
</script>

<style scoped>
.quiz-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: 100% 100%;
  padding: 20px;
  box-sizing: border-box;
}
.question {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
    height: 20vh;
    width: 85%;
    padding: 5vh 0;
    text-align: center;
    background-color: rgba(167, 211, 219);
    border-radius: 28px;
    border: 6px solid black;
    font-size: 24px;
    margin-bottom: 1vh;
}
.options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.option {
    width: 88%;
    cursor: pointer;
    transition: transform 0.2s;
    margin-left: 6%;
    border-radius: 18px;
    border: 5px solid black;
    background-color: #fff;
}
.option-inside {
    font-size: 24px;
    margin-bottom: 1vh;
    width: 90%;
    height: 80%;
    background-color: rgba(167, 211, 219);
    margin-left: 10%;
    border-radius: 13px;
}

.option:hover {
  transform: scale(1.05);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.completion-screen {
    width: 100vh;
    height: 100vh;
}
.completion-overlay{
    /* position: fixed; */
    z-index: 999;
    top:0;
    width: 100%;
    height: 100vh;
    background-color: rgba(22, 21, 21, 0.1); /* 灰色半透明 */
    display: flex;
    justify-content: center;
    align-items: center;
    
}

#clickableImage {
    width: 37vh;
    transition: transform 0.3s ease; /* 放大效果 */
    cursor: pointer;
}
.subtitle {
  font-size: 20px;
  margin-top: 10px;
}
</style>
