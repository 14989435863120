import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../components/HomePage.vue'
import TestDetail from '../components/TestDetail.vue'
import QuizPage from '../components/QuizPage.vue'
import ResultPage from '../components/ResultPage.vue'
import AboutPage from '../components/AboutPage.vue'
import PolicyPage from '../components/PolicyPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/question',
    name: 'question',
    component: QuizPage
  },
  {
    path: '/result',
    name: 'result',
    component: ResultPage
  },
  {
    path: '/test/:id',
    name: 'TestDetail',
    component: TestDetail,
    props: true
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  },
  {
    path: '/policy',
    component: PolicyPage,
    props: true
  },
  {
    path: '/about',
    component: AboutPage,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
