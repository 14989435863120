<!-- App.vue -->
<template>
  <router-view />
  <div class="footer-bottom">
      <div class="left-bottom">© 2024 resoukandian.com</div>
      <div class="right-bottom">
        <div class="procy">
          <router-link to="/policy">Privacy Policy</router-link>
        </div>
        <div class="term">
          <router-link to="/about">Terms of Service</router-link>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'App',
};
</script>
